import React from 'react'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import goodVibes from '../../assets/home/goodVibes/goodVibes.svg'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import ButtonLink from '../../components/common/buttons/ButtonLink'
import { breakpoint } from '../../configs/utilities'
import { SectionText } from '../../components/common/typography/Typography'

const GoodVibesSection = ({ intl }) => {
  return (
    <SectionWrapper>
      <Wrapper>
        <ImageWrapper>
          <img src={goodVibes} alt="good vibes" />
        </ImageWrapper>
        <TextButtonWrapper>
          <SectionText>{intl.formatMessage({ id: 'home.goodVibes.text' })}</SectionText>
          <ButtonLink to="/activities" buttonText={intl.formatMessage({ id: 'home.goodVibes.button' })} />
        </TextButtonWrapper>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(GoodVibesSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 80px;
  row-gap: 50px;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
  }
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 70%;
    @media screen and (min-width: ${breakpoint.md}) {
      width: 100%;
    }
  }
`
const TextButtonWrapper = styled.div`
  max-width: 581px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  text-align: center;

  @media screen and (min-width: ${breakpoint.md}) {
    text-align: left;
    align-items: flex-start;
  }
`
