import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { breakpoint, color } from '../../../configs/utilities'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useWindowSize } from '../../../configs/getGlobalWindowSize'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const LevelsSlider = ({ intl, sliders }) => {
  const currentWidth = useWindowSize().width
  const showNavigation = currentWidth > 768 && sliders.length > 1 ? true : false
  const isSlider = sliders.length > 1 ? true : false

  const isAutoplay = () => {
    if (!isSlider) {
      return false
    }
    return {
      delay: 15000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    }
  }

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      allowSlideNext={isSlider}
      allowSlidePrev={isSlider}
      autoplay={isAutoplay()}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={showNavigation}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {sliders.map((slider, index) => {
        return (
          <SwiperSlide key={index}>
            <SliderContent>
              <ImageWrapper>
                <GatsbyImage
                  image={
                    currentWidth > 768
                      ? slider.imageXl.childImageSharp.gatsbyImageData
                      : slider.imageSm.childImageSharp.gatsbyImageData
                  }
                  alt={intl.formatMessage({ id: slider.title })}
                />
              </ImageWrapper>
              <TextWrapper>
                <SliderTabTitle>{intl.formatMessage({ id: slider.tabTitle })}</SliderTabTitle>
                <SliderTitle>{intl.formatMessage({ id: slider.title })}</SliderTitle>
                <SliderText>{intl.formatMessage({ id: slider.description })}</SliderText>
                {slider.options !== null && (
                  <OptionsWrapper>
                    {slider.options.map((item, index) => {
                      return (
                        <OptionsItem key={index}>
                          <GatsbyImage
                            image={item.icon.childImageSharp.gatsbyImageData}
                            alt={intl.formatMessage({ id: item.text })}
                          />
                          <SliderText>{intl.formatMessage({ id: item.text })}</SliderText>
                        </OptionsItem>
                      )
                    })}
                  </OptionsWrapper>
                )}
              </TextWrapper>
            </SliderContent>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default injectIntl(LevelsSlider)

const SliderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10% 10% 5%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media screen and (min-width: ${breakpoint.md}) {
    padding: 5% 10%;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    flex-direction: row;
    column-gap: 80px;
    padding: 80px 60px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(8, 30, 74, 0.1);
  border-radius: 8px;

  @media screen and (min-width: ${breakpoint.xl}) {
    width: 50%;
  }

  img {
    border-radius: 8px;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 40px 0;
  text-align: left;

  @media screen and (min-width: ${breakpoint.xl}) {
    width: 50%;
    padding: 0;
    row-gap: 20px;
    max-width: 600px;
  }
`

const SliderTabTitle = styled.span`
  font-weight: 300;
  font-size: 20px;
  color: ${color.white};
  opacity: 0.5;

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 22px;
  }
  @media screen and (min-width: ${breakpoint.xxl}) {
    font-size: 24px;
  }
`

const SliderTitle = styled.span`
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 26px;
  letter-spacing: 1.5px;
  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 30px;
  }
  @media screen and (min-width: ${breakpoint.xxl}) {
    font-size: 34px;
    letter-spacing: 2px;
  }
`

const SliderText = styled.span`
  font-weight: 300;
  font-size: 14px;
  color: ${color.white};

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 16px;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    font-size: 18px;
  }
`

const OptionsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

const OptionsItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
`
