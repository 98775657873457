import React from 'react'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import wiFi from '../../assets/home/entertainment/wiFi.svg'
import tv from '../../assets/home/entertainment/tv.svg'
import speaker from '../../assets/home/entertainment/speaker.svg'
import netflix from '../../assets/home/entertainment/netflix.svg'
import styled from 'styled-components'
import { breakpoint, color } from '../../configs/utilities'
import { SectionText } from '../../components/common/typography/Typography'

const EntertainmentSection = () => {
  return (
    <SectionWrapper>
      <Wrapper>
        <Item>
          <ImageWrapper>
            <img src={wiFi} alt="wifi" className="icon" />
          </ImageWrapper>
          <SectionText>WiFi</SectionText>
        </Item>
        <Item>
          <ImageWrapper>
            <img src={tv} alt="tv" className="icon" />
            <img src={netflix} alt="netflix" className="netflix" />
          </ImageWrapper>
          <SectionText>Qled Tv 65 inca smart</SectionText>
        </Item>
        <Item>
          <ImageWrapper>
            <img src={speaker} alt="speaker" className="icon" />
          </ImageWrapper>
          <SectionText>JBL party box 310 karaoke</SectionText>
        </Item>
      </Wrapper>
    </SectionWrapper>
  )
}

export default EntertainmentSection

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
  border: 1px solid ${color.gray};
  box-shadow: 0px 4px 8px rgba(15, 16, 19, 0.6);
  border-radius: 8px;
  padding: 30px 10px;
  width: 90%;
  margin: 0 auto;

  @media screen and (min-width: ${breakpoint.md}) {
  width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
  width: 80%;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
  }
  .icon {
    width: 40px;

    @media screen and (min-width: ${breakpoint.xl}) {
      width: 50px;
    }
  }
  .netflix {
    width: 90px;
    border-radius: 8px;

    @media screen and (min-width: ${breakpoint.xl}) {
      width: 100px;
    }
  }
`
