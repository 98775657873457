import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import { useStaticQuery, graphql } from 'gatsby'
import { useWindowSize } from '../../configs/getGlobalWindowSize'
import { breakpoint, color, transition } from '../../configs/utilities'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SectionTitle, SectionDescription, SectionText } from '../../components/common/typography/Typography'

const Restaurant = ({ intl }) => {
  const data = useStaticQuery(graphql`
    {
      restaurant01Xl: file(relativePath: { eq: "home/restaurant/restaurant01Xl.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            breakpoints: [768, 1380]
          )
        }
      }
      restaurant01Sm: file(relativePath: { eq: "home/restaurant/restaurant01Sm.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 800, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      restaurant02Xl: file(relativePath: { eq: "home/restaurant/restaurant02Xl.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            breakpoints: [768, 1380]
          )
        }
      }
      restaurant02Sm: file(relativePath: { eq: "home/restaurant/restaurant02Sm.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 800, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const currentWidth = useWindowSize().width

  return (
    <SectionWrapper>
      <Wrapper>
        <TextWrapper>
          <SectionTitle>{intl.formatMessage({ id: 'home.restaurant.title' })}</SectionTitle>
          <SectionDescription>{intl.formatMessage({ id: 'home.restaurant.description' })}</SectionDescription>
          <SectionText>
            {intl.formatMessage({ id: 'home.restaurant.text' })}
            <a href="http://www.vilahorizont.co.rs/restoran.php" target="_blank" rel="noopener noreferrer">
              {intl.formatMessage({ id: 'home.restaurant.linkText' })}
            </a>
          </SectionText>
        </TextWrapper>
        <ImageWrapper>
          <GatsbyImage
            image={
              currentWidth > 768
                ? data.restaurant01Xl.childImageSharp.gatsbyImageData
                : data.restaurant01Sm.childImageSharp.gatsbyImageData
            }
            alt="Horse Image"
          />
          <GatsbyImage
            image={
              currentWidth > 768
                ? data.restaurant02Xl.childImageSharp.gatsbyImageData
                : data.restaurant02Sm.childImageSharp.gatsbyImageData
            }
            alt="Horse Image"
          />
        </ImageWrapper>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(Restaurant)

const Wrapper = styled.div`
  a {
    margin-left: 5px;
    color: ${color.green};
    transition: ${transition.default};

    @media (hover: hover) {
      &:hover {
        opacity: 0.7;
      }
    }
  }
`

const TextWrapper = styled.div`
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 50px;
  text-align: center;
  @media screen and (min-width: ${breakpoint.xl}) {
    max-width: 820px;
    margin: 0 auto 80px;
  }
`

const ImageWrapper = styled.div`
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    column-gap: 30px;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    max-width: 100%;
  }
  img {
    border-radius: 18px;
  }
`
