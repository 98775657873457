import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { breakpoint, transition } from '../../configs/utilities'
import heroSm from '../../assets/home/hero/heroSm.webp'
import heroMd from '../../assets/home/hero/heroMd.webp'
import heroXl from '../../assets/home/hero/heroXl.webp'
import { injectIntl } from 'gatsby-plugin-react-intl'
import BookNowComponent from '../../components/sections/BookNowComponent'
import { PageTitle, HeroDescription } from '../../components/common/typography/Typography'
import HeroSlider from '../../components/common/slider/HeroSlider'
import { showContainer } from '../../configs/animations'

const Hero = ({ intl }) => {
  const data = useStaticQuery(graphql`
    {
      booking: file(relativePath: { eq: "home/hero/booking.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      instagram: file(relativePath: { eq: "home/hero/instagram.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      whatsapp: file(relativePath: { eq: "home/hero/whatsapp.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)
  const [playAnimation, setPlayAnimation] = useState(false)
  const containerContent = useRef(null)

  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true)
    }

    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
      return () => window.removeEventListener('load', onPageLoad)
    }
    if (playAnimation) {
      showContainer(containerContent)
    }
  }, [playAnimation])
  return (
    <AnimationWrapper ref={containerContent}>
      <Wrapper>
        <IconDescriptionWrapper>
          <IconsWrapper>
            <a
              href="https://www.booking.com/hotel/rs/v-house-goc.sr.html?aid=1263239&label=PShare-Pulse-u5BODO%401666472243&sid=23798eea8e9f9482cb66c440c66e20da&dist=0&keep_landing=1&sb_price_type=total&type=total&"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage image={data.booking.childImageSharp.gatsbyImageData} alt="booking" />
            </a>

            <a
              href="https://www.instagram.com/vhousegoc/?igshid=ZjA0NjI3M2I%3D"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage image={data.instagram.childImageSharp.gatsbyImageData} alt="instagram" />
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Chat on WhatsApp"
              href="https://wa.me/381640007090"
            >
              <GatsbyImage image={data.whatsapp.childImageSharp.gatsbyImageData} alt="instagram" />
            </a>
          </IconsWrapper>
          <HeroDescription>{intl.formatMessage({ id: 'home.hero.welcome' })}</HeroDescription>
        </IconDescriptionWrapper>
        <PageTitle>{intl.formatMessage({ id: 'home.hero.perfectPlace' })}</PageTitle>
        <BookNowComponent />
        <HeroSlider />
      </Wrapper>
    </AnimationWrapper>
  )
}

export default injectIntl(Hero)

const AnimationWrapper = styled.div`
  visibility: hidden;
`

const Wrapper = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: url(${heroSm});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  border-radius: 8px;
  max-width: 1980px;
  padding: 40px 0 0 0;

  @media screen and (min-width: ${breakpoint.md}) {
    background-image: url(${heroMd});
    padding: 70px 0 0 0;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    background-image: url(${heroXl});
    background-size: contain;
  }

  @media screen and (min-width: ${breakpoint.huge}) {
    min-height: auto;
    row-gap: 110px;
  }
`

const IconDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  p {
    margin: 0 auto;
  }

  @media screen and (min-width: ${breakpoint.huge}) {
    margin-top: 50px;
  }
`

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 40px;

  a {
    box-shadow: 0px 4px 8px rgba(15, 16, 19, 0.6);
    transition: ${transition.default};
    &:hover {
      opacity: 0.7;
    }
  }
`
