import React, { useState } from 'react'
import styled from 'styled-components'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { Marker, InfoWindow } from '@react-google-maps/api'
import mapStyles from './MapStyles'
import { useWindowSize } from '../../../configs/getGlobalWindowSize'
import { color } from '../../../configs/utilities'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-react-intl'

const containerStyle = {
  width: '100%',
  height: '700px',
}

function VhouseMap() {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      allPlacesJson {
        edges {
          node {
            icon {
              publicURL
            }
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  breakpoints: [320, 768, 1380]
                )
              }
            }
            position {
              lat
              lng
            }
            text
          }
        }
      }
    }
  `)

  const [showMarker, setShowMarker] = useState([false])
  const currentWidth = useWindowSize().width

  const toggleMarker = (value) => {
    let newArr = [...showMarker]
    newArr[value] = !showMarker[value]
    const finalArray = newArr.map((item, index) => {
      return value === index ? !showMarker[index] : false
    })
    setShowMarker(finalArray)
  }

  const divStyle = {
    padding: 0,
    color: color.black,
    fontSize: currentWidth > 768 ? 20 : 14,
    fontWeight: 200,
  }

  const getZoom = () => {
    return currentWidth > 1280 ? 14 : 13
  }

  return (
    <Wrapper>
      <LoadScript googleMapsApiKey="AIzaSyDKIIuwtE5mdxuaENVN9kFDIlHSa2xMku0">
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={getZoom()}
          center={{
            lat: 43.5555555,
            lng: 20.8855555,
          }}
          options={{
            styles: mapStyles,
            mapTypeControl: true,
            streetViewControl: true,
          }}
        >
          {data.allPlacesJson.edges.map((item, index) => {
            return (
              <Marker
                key={index}
                icon={item.node.icon.publicURL}
                position={item.node.position}
                onClick={() => {
                  toggleMarker(index)
                }}
              >
                {showMarker[index] && (
                  <InfoWindow
                    position={item.node.position}
                    onCloseClick={() => {
                      toggleMarker(index)
                    }}
                  >
                    <div style={divStyle}>
                      <p>{intl.formatMessage({ id: item.node.text })}</p>
                      <GatsbyImage
                        image={item.node.image.childImageSharp.gatsbyImageData}
                        alt={intl.formatMessage({ id: item.node.text })}
                      />
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            )
          })}
        </GoogleMap>
      </LoadScript>
    </Wrapper>
  )
}

export default React.memo(VhouseMap)

const Wrapper = styled.div``
