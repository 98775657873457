import React from 'react'
import styled from 'styled-components'
import HeroSection from '../sections/home/HeroSection'
import DescriptionSection from '../sections/home/DescriptionSection'
import LevelsSection from '../sections/home/LevelsSection'
import EntertainmentSection from '../sections/home/EntertainmentSection'
import VideoSection from '../sections/home/VideoSection'
import Restaurant from '../sections/home/Restaurant'
import GoodVibesSection from '../sections/home/GoodVibesSection'
import GoogleMapSection from '../sections/home/GoogleMapSection'
import Layout from '../components/layout/Layout'

const Home = () => {
  return (
    <Layout pageName="home">
      <Wrapper>
        <HeroSection />
        <DescriptionSection />
        <LevelsSection />
        <EntertainmentSection />
        <VideoSection />
        <Restaurant />
        <GoodVibesSection />
        <GoogleMapSection />
      </Wrapper>
    </Layout>
  )
}

export default Home

const Wrapper = styled.div``