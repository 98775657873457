import React from 'react'
import videoBackgroundSm from '../../assets/home/video/videoBackgroundSm.webp'
import videoBackgroundMd from '../../assets/home/video/videoBackgroundMd.webp'
import videoBackgroundXl from '../../assets/home/video/videoBackgroundXl.webp'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import { injectIntl } from 'gatsby-plugin-react-intl'
import VideoComponent from '../../components/sections/VideoComponent'

const VideoSection = ({ intl }) => {
  return (
    <SectionWrapper>
      <VideoComponent
        title={intl.formatMessage({ id: 'home.takeTour' })}
        videoId="768949463"
        heroSm={videoBackgroundSm}
        heroMd={videoBackgroundMd}
        heroXl={videoBackgroundXl}
      />
    </SectionWrapper>
  )
}

export default injectIntl(VideoSection)
