import React from 'react'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import VhouseMap from '../../components/common/map/VhouseMap'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { SectionText } from '../../components/common/typography/Typography'

const GoogleMapSection = ({ intl }) => {
  return (
    <SectionWrapper>
      <Wrapper>
        <SectionText>{intl.formatMessage({ id: 'home.address' })}</SectionText>
        <VhouseMap />
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(GoogleMapSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`
