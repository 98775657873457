import React from 'react'
import styled from 'styled-components'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import { SectionTitle, SectionText } from '../../components/common/typography/Typography'
import { injectIntl } from 'gatsby-plugin-react-intl'


const DescriptionSection = ({ intl }) => {
  return (
    <SectionWrapper>
      <Wrapper>
        <SectionTitle>{intl.formatMessage({ id: 'home.descriptionSection.title' })}</SectionTitle>
        <SectionText>{intl.formatMessage({ id: 'home.descriptionSection.text01' })}</SectionText>
        <SectionText>{intl.formatMessage({ id: 'home.descriptionSection.text02' })}</SectionText>
        <SectionText>{intl.formatMessage({ id: 'home.descriptionSection.text03' })}</SectionText>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(DescriptionSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 30px;
  max-width: 1250px;
  margin: 0 auto;
`