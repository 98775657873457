import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import { useStaticQuery, graphql } from 'gatsby'
import { breakpoint, color, transition } from '../../configs/utilities'
import LevelsSlider from '../../components/common/slider/LevelsSlider'
import { useQueryParam } from 'gatsby-query-params'
import { injectIntl } from 'gatsby-plugin-react-intl'

const LevelsSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allLevelsJson {
        edges {
          node {
            slug
            tabTitle
            sliders {
              imageXl {
                childImageSharp {
                  gatsbyImageData(
                    width: 700
                    quality: 100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    breakpoints: [768, 1380]
                  )
                }
              }
              imageSm {
                childImageSharp {
                  gatsbyImageData(width: 700, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
              description
              tabTitle
              title
              options {
                icon {
                  childImageSharp {
                    gatsbyImageData(
                      width: 25
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      breakpoints: [320, 768, 1380]
                    )
                  }
                }
                text
              }
            }
          }
        }
      }
    }
  `)

  const urlParam = useQueryParam('level', 'level0')

  const levelsData = data.allLevelsJson.edges

  const [active, setActive] = useState('level0')

  const handleActiveTab = (el, goTo) => {
    setActive(el)
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(null, null, goTo)
  }

  useEffect(() => {
    setActive(urlParam)
  }, [urlParam])

  return (
    <SectionWrapper>
      <Wrapper>
        <Navigation>
          {levelsData.map((item, index) => {
            return (
              <NavigationTab
                key={index}
                className={` ${item.node.slug === active ? 'active' : ''}`}
                onClick={() => handleActiveTab(item.node.slug, `?level=${item.node.slug}`)}
              >
                {intl.formatMessage({ id: item.node.tabTitle })}
              </NavigationTab>
            )
          })}
        </Navigation>
        <ContentWrapper>
          <Content>
            {levelsData.map((item, index) => {
              return <div key={index}>{active === item.node.slug && <LevelsSlider sliders={item.node.sliders} />}</div>
            })}
          </Content>
        </ContentWrapper>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(LevelsSection)

const Wrapper = styled.div``

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #292d34;
  box-shadow: 0px 12px 24px rgba(15, 16, 19, 0.6);
  border-radius: 0px 0px 8px 8px;
  font-size: 50px;
  text-align: center;
  @media screen and (min-width: ${breakpoint.md}) {
    width: 80%;
    margin: 0 auto;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    width: 100%;
  }
`

const Navigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;

  @media screen and (min-width: ${breakpoint.md}) {
    min-height: 70px;
    width: 80%;
    margin: 0 auto;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    width: 100%;
  }
  .active {
    background: ${color.black};
    box-shadow: inset 0px 0px 0px 1px ${color.blackLight};
  }
`

const NavigationTab = styled.div`
  width: 25%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 300;
  background: ${color.blackLight};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  transition: ${transition.default};

  &:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 4.5vw;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 20px;
  }
  
  @media screen and (min-width: ${breakpoint.xxl}) {
    min-height: 60px;
    font-size: 24px;
    letter-spacing: 2px;
  }
`

const Content = styled.div`
  color: white;
`
