import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { breakpoint, color } from '../../../configs/utilities'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css'
import { useWindowSize } from '../../../configs/getGlobalWindowSize'
import { GatsbyImage } from 'gatsby-plugin-image'

const HeroSlider = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allIconsJson {
        edges {
          node {
            text
            icon {
              childImageSharp {
                gatsbyImageData(width: 40, height: 40, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  `)

  const sliders = data.allIconsJson.edges
  const currentWidth = useWindowSize().width

  const noOfSliders = () => {
    switch (true) {
      case currentWidth >= 1200:
        return 5
      case currentWidth > 576 && currentWidth < 1200:
        return 5
      default:
        return 3
    }
  }

  return (
    <Wrapper>
      <Swiper
        slidesPerView={noOfSliders()}
        centeredSlides={true}
        allowSlideNext={true}
        allowSlidePrev={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        navigation={false}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {sliders.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Item key={index}>
                <GatsbyImage image={item.node.icon.childImageSharp.gatsbyImageData} alt={item.node.text} />
                <Text>{intl.formatMessage({ id: item.node.text })}</Text>
              </Item>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Wrapper>
  )
}

export default injectIntl(HeroSlider)

const Wrapper = styled.div`
  width: 100%;
  padding: 70px 20px 70px 20px;
  background: linear-gradient(0deg, rgba(26, 29, 34, 1) 0%, rgba(26, 29, 34, 0.5) 80%, rgba(26, 29, 34, 0) 100%);

  @media screen and (min-width: ${breakpoint.md}) {
    padding: 130px 15vw 70px 15vw;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    padding: 30px 25vw 100px 25vw;
    background: linear-gradient(0deg, rgba(26, 29, 34, 1) 0%, rgba(26, 29, 34, 0.5) 50%, rgba(26, 29, 34, 0) 100%);
  }

  @media screen and (min-width: ${breakpoint.huge}) {
    padding: 120px 25%;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-left: 0.5px solid ${color.white};
  border-right: 0.5px solid ${color.white};
  row-gap: 15px;
  width: 100%;

  img {
    width: 70%;
    margin: 0 auto;
    @media screen and (max-width: ${breakpoint.xsm}) {
      width: 7vw;
    }
    @media screen and (min-width: ${breakpoint.md}) {
      width: 80%;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      width: 80%;
    }
    @media screen and (min-width: ${breakpoint.xxl}) {
      width: 100%;
    }
  }
`
const Text = styled.div`
  font-weight: 600;
  font-size: 10px;
  max-width: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 30px;

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 3vw;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 14px;
    max-width: 100%;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 16px;
  }
`
